import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Card from '../../components/defaultSnippets/Card';
import { useSelector } from 'react-redux';
import { selectStudentStatus } from '../Students/studentSlice';
import Flatpickr from 'react-flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
import 'flatpickr/dist/themes/material_blue.css'; // Import Flatpickr theme CSS
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,useParams } from 'react-router-dom';

function StudPerformanceEdit() {
    const [students, setStudents] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [performanceData, setPerformanceData] = useState({});
    const [performanceDataUexam, setPerformanceDataUexam] = useState({});
    const [staffperformance, setStaffperformance]=useState({});
    const [searchData, setSearchData] = useState({});
    const studentData = useSelector(selectStudentStatus);
    const [formData, setFormData] = useState({});
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [attendanceData, setAttendanceData] = useState({});
    const navigate = useNavigate();
    const [isUniversityExam, setIsUniversityExam] = useState(false);
    const [uGradeCounts, setUGradeCounts] = useState({});
    const[gradebysubject, setgradeBysubject] = useState({});
    const {id}=useParams()
    const[isLoading, setIsLoading]=useState(true);

     useEffect(() => {
        fetchperformances()
    },[]
    )

const handleMonthRangeChange = (selectedDates) => {
    if (selectedDates.length === 0) {
        console.log("No dates selected");
        setIsButtonEnabled(false);
    } else {
        console.log(selectedDates);
        const formattedDates = selectedDates.map(date => {
            const d = new Date(date);
            return d.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        });

        setSearchData({
            ...searchData,
            perform_month_range: formattedDates,
        });
    }
};
    const semesterOptions = {
        I: ['I', 'II'],
        II: ['III', 'IV'],
        III: ['V', 'VI'],
        IV: ['VII', 'VIII']
        // Add more options based on your requirements
    };

    const fetchStudents = async (searchData,performData) => {
        console.log("searching data="+searchData);
        const findid = toast.loading("Fetching Students data...", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        try {
            const res = await axios.get('/api/performancestudenteditsearchall', {
                params: searchData // Pass searchData as query parameters
            });
           
            setStudents(res.data.results);
            setSubjects(res.data.subjects);
            setStaffperformance(res.data.staffperformance);
            if(res.data.testtype=="University Exam")
            {
                updateUgradeCountsE(res.data.results,performData,res.data.subjects)
            }
         
            toast.dismiss(findid); // Dismiss the loading toast when data is fetched successfully
        }
        catch (err) {
            // If request fails
            console.log(err.response);
            if (err.response && err.response.status === 422) {
                toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
            } else {
                toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
            }
        }
    }

    useEffect(() => {
        if (!searchData.perform_courseid || !searchData.perform_courseyear || !searchData.perform_semester || !searchData.perform_testtype || !searchData.perform_month_range) {
            console.log(searchData);
            setIsButtonEnabled(false);
        } else {
            setIsButtonEnabled(true);
        }
    }, [searchData]);

    const handleChange = (event, studentId, session) => {
        const { value } = event.target;
        // Update attendance data for the selected session and student
        setAttendanceData(prevAttendanceData => ({
            ...prevAttendanceData,
            [`${studentId}_session${session}`]: value
        }));

    };

    const handleperformanceChange = (event, studentId, subjectcode) => {
        const { value } = event.target;
        // Update attendance data for the selected session and student
       

          setPerformanceData(prevPerformanceeData => {
        const updatedData = {
            ...prevPerformanceeData,
            [`${studentId}_${subjectcode}`]: value
        };

        // Initialize an object to store the count of "U" grades for each subject
        const FailGradeCountsBySubject = {};

        // Loop through subjects and count the number of "U" grades in each column
        let studcount=0;
        students.forEach((student) => {
            studcount++
        })
        subjects.forEach((subject) => {
            let FailGradeCount = 0;
            students.forEach((student) => {
                
                const grade = updatedData[`${student.id}_${subject.subjectcode}`] || '';
                if (grade >=50) {
                    FailGradeCount++;
                }
            });
            //alert(studcount)
            FailGradeCountsBySubject[subject.subjectcode] = FailGradeCount*100/studcount;
        });

        console.log("Count of 'U' grades by subject:", FailGradeCountsBySubject);
setgradeBysubject(FailGradeCountsBySubject)
        return updatedData;
    });


    };

    const handleperformanceChangeUexam = (event, studentId, subjectcode) => {
        const { value } = event.target;
        // Update attendance data for the selected session and student
       setPerformanceDataUexam(prevPerformanceDataUexam => {
        const updatedData = {
            ...prevPerformanceDataUexam,
            [`${studentId}_${subjectcode}`]: value
        };

        // Initialize an object to store the count of "U" grades for each subject
        const uGradeCountsBySubject = {};

        // Loop through subjects and count the number of "U" grades in each column
        subjects.forEach((subject) => {
            let uGradeCount = 0;
            students.forEach((student) => {
                const grade = updatedData[`${student.id}_${subject.subjectcode}`] || '';
                if (grade !== 'U') {
                    uGradeCount++;
                }
            });
            uGradeCountsBySubject[subject.subjectcode] = uGradeCount*100/2;
        });

        console.log("Count of 'U' grades by subject:", uGradeCountsBySubject);
setgradeBysubject(uGradeCountsBySubject)
        return updatedData;
    });
      
        

    };

    const fetchperformances = async () => {
    try {
        const res = await axios.get('/api/performance/' + id);
      
        console.log(res);
       const updatedSearchData = res.data.headerdata;
       const updatedPerformanceData = res.data.perfromancedata;
        setSearchData(updatedSearchData);
       
       // alert(res.data.testtype);
if(res.data.testtype=="University Exam")
{
    setIsUniversityExam(true)
     setPerformanceDataUexam(updatedPerformanceData);
     
}
else
{
    setIsUniversityExam(false)
     setPerformanceData(updatedPerformanceData);
}
//return false;
        // Assuming fetchStudents needs the updated searchData
        await fetchStudents(updatedSearchData,updatedPerformanceData);
       
  setIsLoading(false);
        
    } catch (err) {
        console.log(err.response);
    }
};
   
useEffect(() => {
        // Update U-grade counts whenever performanceDataUexam changes
       // updateUgradeCountsE();
    }, [performanceDataUexam]);

    useEffect(() => {
        // Update U-grade counts whenever performanceDataUexam changes
        updateFailgradeCounts();
    }, [performanceData]);
const updateFailgradeCounts = () => {
        // Calculate the count of "U" grades for each student
        const newUGradeCounts = {};
        students.forEach(student => {
            const uCount = subjects.reduce((count, subject) => {
                const performanceValue = performanceData[`${student.id}_${subject.subjectcode}`] || '';
                //alert(performanceValue)
                if (performanceValue < 50) {
                    count++;
                }
                return count;
            }, 0);
            newUGradeCounts[student.id] = uCount;
        });
        // Update the counts in the state
        setUGradeCounts(newUGradeCounts);
    };
    const updateUgradeCountsE = (studs,performs,subs) => {
        // Calculate the count of "U" grades for each student
        //alert("welcome");
        console.log(performs);
        const newUGradeCounts = {};
        studs.forEach(student => {
            //alert(student.id)
            const uCount = subs.reduce((count, subject) => {
                const performanceValue = performs[`${student.id}_${subject.subjectcode}`] || '';
                console.log(performanceValue);
                if (performanceValue === 'U') {
                    count++;
                }
                return count;
            }, 0);
            newUGradeCounts[student.id] = uCount;
        });
        console.log(newUGradeCounts);
        // Update the counts in the state
        setUGradeCounts(newUGradeCounts);
    };
   const updateUgradeCounts = () => {
        // Calculate the count of "U" grades for each student
        //alert("welcome");
        console.log(performanceDataUexam);
        const newUGradeCounts = {};
        students.forEach(student => {
            const uCount = subjects.reduce((count, subject) => {
                const performanceValue = performanceDataUexam[`${student.id}_${subject.subjectcode}`] || '';
                console.log(performanceValue);
                if (performanceValue === 'U') {
                    count++;
                }
                return count;
            }, 0);
            newUGradeCounts[student.id] = uCount;
        });
        console.log(newUGradeCounts);
        // Update the counts in the state
        setUGradeCounts(newUGradeCounts);
    };
const simulateChangeEvent = (name, value) => {
    const event = { target: { name, value } };
    handleSearchChange(event);
};


    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [name]: value,
        }));
       // alert(value);
        if(searchData.perform_testtype=="University Exam")
        {
            setIsUniversityExam(true); // Update state variable if value is "University Exam"
        }
        else {
            setIsUniversityExam(false); // Reset state variable if value is not "University Exam"
        }

        // const isUniversityExam = searchData.perform_testtype === "University exam";
    };

        const handleSearch=(e) => {
    e.preventDefault();
    console.log(searchData);
   // return false
    fetchStudents()
}

    const handleDateChange = (selectedDates, dateField) => {
        const selectedDate = selectedDates[0];
        let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
        formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [dateField]: formattedDate,
        }));
    };
 const handleUexamSubmit = (event) => {
        event.preventDefault();
        // Handle submission of attendance data
        console.log(performanceData);
        //return false;
        const dataToSubmit = {
        ...performanceDataUexam,
        ...searchData,
      //  ...gradebysubject
    };

    // Handle submission of attendance data
    console.log(dataToSubmit);
    dataToSubmit['perform_month_range']=searchData.perform_month_range[0]+" to "+searchData.perform_month_range[1]
    console.log(dataToSubmit);
    //return false;
    SendData(dataToSubmit);
     //   SendData()
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle submission of attendance data
        console.log(performanceData);
        //return false;
        const dataToSubmit = {
        ...performanceData,
        ...searchData,
        // ...gradebysubject
    };

    // Handle submission of attendance data
    console.log(dataToSubmit);
    dataToSubmit['perform_month_range']=searchData.perform_month_range[0]+" to "+searchData.perform_month_range[1]
    console.log(dataToSubmit);
    //return false;
    SendData(dataToSubmit);
     //   SendData()
    };

    const SendData = async(dataToSubmit) =>{
     const updateid =  toast.loading("Saving Performance data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.put(`/api/performanceupdate/${id}`, dataToSubmit);

        // If request is successful
        console.log(res.data);
        toast.update(updateid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/studentperformance');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(updateid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(updateid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }

    return (
        <>
            <ToastContainer />
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Edit Performance</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form >
                                <Row className="align-items-center">
                                    <Col sm="4">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="perform_courseid"
                                                id="perform_courseid"
                                                className="selectpicker form-control"
                                                value={searchData.perform_courseid}
                                                onChange={handleSearchChange}
                                                disabled
                                            >
                                                <option value=''>Select Course</option>
                                                {studentData.coursenames.data.results.map((course, index) => (
                                                    <option key={index} value={course.id}>
                                                        {course.coursename}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select name="perform_courseyear" id="perform_courseyear" className="selectpicker form-control" data-style="py-0" value={searchData.perform_courseyear} onChange={handleSearchChange} disabled>
                                                <option value=''>Select Year</option>
                                                <option>I</option>
                                                <option>II</option>
                                                <option>III</option>
                                                <option>IV</option>
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="3">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select name="perform_semester" id="perform_semester" className="selectpicker form-control" data-style="py-0" value={searchData.perform_semester} onChange={handleSearchChange} disabled>
                                                <option value=''>Select Semester</option>
                                                {semesterOptions[searchData.perform_courseyear] &&
                                                    semesterOptions[searchData.perform_courseyear].map((semester, index) => (
                                                        <option key={index}>{semester}</option>
                                                    ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="3">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select name="perform_testtype" id="perform_testtype" className="selectpicker form-control" data-style="py-0" value={searchData.perform_testtype} onChange={handleSearchChange} disabled>
                                                <option value=''>Select Test Type</option>
                                                <option>Class Test-I</option>
                                                <option>Cycle Test - I</option>
                                                <option>IA - I</option>
                                                <option>Class Test-II</option>
                                                <option>Cycle Test-II</option>
                                                <option>IA - II</option>
                                                <option>Coaching - I</option>
                                                <option>Coaching - II</option>
                                                <option>Coaching - III</option>
                                                <option>Coaching - IV</option>
                                                <option>Coaching - V</option>
                                                <option>model - I</option>
                                                <option>Model - II</option>
                                                <option>University Exam</option>
                                            </select>
                                        </Form.Group>
                                    </Col>
                                      <Col sm="4" className='mt-4'>
            <Form.Group className="mb-3 mb-sm-0">
                 <input className='form-control text-center' name={searchData.perform_month_range}  value={searchData.perform_month_range} disabled></input>
           
            </Form.Group>
        </Col>
         <Col sm="2">
                                        <Form.Group className="mt-4">
                                            <select name="perform_testoption" id="perform_testoption" className="selectpicker form-control" data-style="py-0" value={searchData.perform_testoption} onChange={handleSearchChange} disabled>
                                                <option value=''>Select</option>
                                                <option>Normal</option>
                                                <option>Re-test</option>
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="1" className='mt-4'>
                                        <div className="text-end">
                                            {/* <Button type="button" id="searchbutton" variant="primary" onClick={handleSearch} disabled>Proceed</Button> */}
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm="12" style={{fontSize:'14px'}}>
                    <Card>
                        <Card.Body>
                           
                            {/* <h2 className='text-start'>Details</h2> */}
                            <Row className='mb-2' style={{backgroundColor:'#2664CF',color:'#fff',padding:'4px'}}>
                                
                               
                                <Col sm="1"><b>Sl. No.</b></Col>
                                <Col sm="2"><b>Subject Code</b></Col>
                                <Col sm="4"><b>Subject Title</b></Col>
                                <Col sm="2"><b>Staff Name</b></Col>
                                <Col sm="3"><b>Result %</b></Col>
                            </Row>
                               {subjects.map((subject, subjectIndex) => (
                                 <Row className='mb-2' style={{backgroundColor:'#fff',color:'#000',padding:'4px'}}>
                                    <Col sm="1">{subjectIndex+1}</Col>
                                        <Col key={subject} sm="2">
                                            <b>{subject.subjectcode}</b>
                                        </Col>
                                        <Col key={subject} sm="4" className='text-start'>
                                            <b>{subject.subjectname}</b>
                                        </Col>
                                        <Col key={subject} sm="2" className='text-start'>
                                            <b>{subject.subjecthandledbyname}</b>
                                        </Col>
                                        <Col key={subject} sm="2" className='text-center'>
                                            <input className='text-center' name={subject.subjectname} value={gradebysubject[subject.subjectcode]} disabled></input>
                                        </Col>
                                </Row>
                                    ))}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
              {isLoading ? (<><p>Loading data</p></>):(<>
              {isUniversityExam ? (
                /* University Exam Form */
                 <Row>
                <Col sm="12">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleUexamSubmit}>
                                <h5 className='text-start' >Performance Input Form (University Exam)</h5>
                                <Row className='mb-2' style={{backgroundColor:'#2664CF',color:'#fff',padding:'4px'}}>
                                    <Col sm="2" className='text-start'>
                                            <b style={{fontSize:'14px'}}>Student Name</b>
                                    </Col>
                                     {subjects.map((subject, subjectIndex) => (
                                        <Col key={subject} sm="1">
                                            <b style={{fontSize:'14px'}}>{subject.subjectcode}</b>
                                        </Col>
                                    ))}
                                    <Col sm="2">
                                            <b style={{fontSize:'14px'}}>Current Arrear Status</b>
                                    </Col>
                                     <Col sm="2">
                                            <b style={{fontSize:'14px'}}>Previous Arrear Status</b>
                                    </Col>
                                    {/* <Col sm="1"><b>Session 1</b></Col>
                                    <Col sm="1"><b>Session 2</b></Col>
                                    <Col sm="1"><b>Session 3</b></Col>
                                    <Col sm="1"><b>Session 4</b></Col>
                                    <Col sm="1"><b>Session 5</b></Col>
                                    <Col sm="1"><b>Session 6</b></Col>
                                    <Col sm="1"><b>Session 7</b></Col>
                                    <Col sm="1"><b>Session 8</b></Col> */}

                                </Row>
                                {students.map((student, studentIndex) => (
                                    <div key={student.id}>
                                        <Row className='mb-3' style={{borderBottom:'1px solid grey',paddingBottom:'6px'}}>
                                            {/* Student Name column */}
                                            <Col sm="2" className='mt-2 text-start'>
                                                <h6>{student.first_name}</h6>
                                            </Col>
                                            {subjects.map((subject, sessionIndex) => {
                                               // const performanceKey = `perform_marks_${studentIndex}_${sessionIndex + 1}`;
                                                 const performanceKey = `${student.id}_${subject.subjectcode}`;//`perform_marks_${studentIndex}_${sessionIndex + 1}`;
                                               // const performanceValue = performanceDataUexam[`${student.id}_${subject.subjectcode}`] || "";
                                                const performanceValue = performanceDataUexam[`${student.id}_${subject.subjectcode}`] || 0;
                                                return (
                                                    <Col key={sessionIndex} sm="1">
                                                        <Form.Group>
                                                            <select 
                                                                name={performanceKey}  
                                                                id={performanceKey}  
                                                                className="selectpicker form-control text-center" 
                                                                data-style="py-0" 
                                                                onChange={(event) => handleperformanceChangeUexam(event, student.id, subject.subjectcode)}
                                                                value={performanceValue}
                                                            >
                                                                <option value=''>Select</option>
                                                                <option>A+</option>
                                                                <option>A</option>
                                                                <option>B</option>
                                                                <option>C</option>
                                                                <option>U</option>
                                                            </select>
                                                        </Form.Group>
                                                    </Col>
                                                );
                                            })}
                                            {/* Count U grades column */}
                                             <Col sm="1">
                                                <div className="text-center d-flex align-items-center mt-2">
                                                    {/* Display the count of "U" grades */}
                                                    {uGradeCounts[student.id] || 0}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Button type="submit">Save</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            ) : (
                /* Regular Performance Input Form */
                <Row>
                <Col sm="12">
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <h5 className='text-start' >Performance Input Form (Internal Tests)</h5>
                                <Row className='mb-2' style={{backgroundColor:'#2664CF',color:'#fff',padding:'4px'}}>
                                    <Col sm="3">
                                            <b style={{fontSize:'14px'}}>Student Name</b>
                                    </Col>
                                     {subjects.map((subject, subjectIndex) => (
                                        <Col key={subject} sm="1">
                                            <b style={{fontSize:'14px'}}>{subject.subjectcode}</b>
                                        </Col>
                                    ))}
                                    
                                    <Col sm="1">
                                            <b style={{fontSize:'14px'}}>Status</b>
                                    </Col>
                                    {/* <Col sm="1"><b>Session 1</b></Col>
                                    <Col sm="1"><b>Session 2</b></Col>
                                    <Col sm="1"><b>Session 3</b></Col>
                                    <Col sm="1"><b>Session 4</b></Col>
                                    <Col sm="1"><b>Session 5</b></Col>
                                    <Col sm="1"><b>Session 6</b></Col>
                                    <Col sm="1"><b>Session 7</b></Col>
                                    <Col sm="1"><b>Session 8</b></Col> */}

                                </Row>
                                {students.map((student, studentIndex) => (
    <div key={student.id}>
        <Row className='mb-3' style={{borderBottom:'1px solid grey',paddingBottom:'6px'}}>
            <Col sm="3">
                <h6>{student.first_name}</h6>
            </Col>
            {subjects.map((subject, sessionIndex) => {
                const performanceKey = `${student.id}_${subject.subjectcode}`;//`perform_marks_${studentIndex}_${sessionIndex + 1}`;
                console.log(performanceData[`${student.id}_${subject.subjectcode}`]);
                const performanceValue = performanceData[`${student.id}_${subject.subjectcode}`] || 0;
                const fail = performanceValue < 50;
                const pass = performanceValue >= 50;
                return (
                    <Col key={sessionIndex} sm="1">
                        <Form.Group>
                            <input 
                                style={{width:'78px'}}
                                type="number" 
                                name={performanceKey} 
                                id={performanceKey} 
                                className="form-control text-center" 
                                placeholder="Marks" 
                                min="0" 
                                max="100" 
                                step="1"   
                                onChange={(event) => handleperformanceChange(event, student.id, subject.subjectcode)}    value={performanceValue}/>
                              
                        </Form.Group>
                    </Col>
                );
            })}
            {/* New column for pass/fail indication */}
            
            <Col sm="1">
                <div className="text-center">
                    {/* Check if all marks are above or equal to 50 */}
                    {subjects.every(subject => performanceData[`${student.id}_${subject.subjectcode}`] >= 50) ? (
                        <p className="text-success">PASS</p>
                    ) : (
                        <p className="text-danger">FAIL</p>
                    )}
                </div>
            </Col>
        </Row>
    </div>
))}
                                <Button type="submit">Submit Attendance</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            )}
             </> )}
           
        </>
    );
}

export default StudPerformanceEdit;
