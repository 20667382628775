
import React, { useEffect } from 'react'
import { Row,Col,Table,Form,Button,Modal } from 'react-bootstrap'
import Card from '../../components/defaultSnippets/Card'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { FaEdit,FaTrashAlt  } from 'react-icons/fa';
import axios from '../../api/axios'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus,FaSearch,FaPrint } from 'react-icons/fa'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
const StaffSalaryslipLandingMain = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
const[data, setData] = useState([]);
const[deleteId,setDeleteId]=useState('')
const [showConfirm, setShowConfirm] = useState(false);
const [salaryslipinfo, setSalaryslipinfo] = useState('');
const [lastRow, setLastRow]=useState(0);
const handleClose = () => setShowConfirm(false);


  const fetchData = async() =>{
     const id =  toast.loading("Fetching data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  await axios.get('/api/salarysliplist')
    .then(res=> {
      console.log(res);
      toast.update(id, { render: "Successfull", type: "success", isLoading: false, autoClose: 1000,theme: "light"}); setData(res.data.results);
  })
    .catch(err=>console.log(err))
  }
 useEffect(() => {
  fetchData()
 },[])

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filter the data based on the search term
  const filteredData = data.filter((item) => {
    console.log(item)
    return (
      item.master_month.toLowerCase().includes(searchTerm.toLowerCase())
    
     
    
    );
  });

  // Recalculate total entries and total pages based on filtered data
  const totalEntries = filteredData.length;
  const totalPages = Math.ceil(totalEntries / itemsPerPage);

  // Slice the filtered data for the current page
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const firstEntryIndex = Math.min((currentPage - 1) * itemsPerPage + 1, totalEntries);
  const lastEntryIndex = Math.min(currentPage * itemsPerPage, totalEntries);
  const showconfirm=(id)=>{
    setShowConfirm(true)
    setDeleteId(id);
   // handleDelete(id)
  }
  const  handleDelete=async (id)=>
  {
    const deleteid =  toast.loading("Fetching data...",{
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
   });
await axios.delete('/api/invoicedelete/'+id)
.then(res=> {
  handleClose()
const newStudentData=data.filter((item)=>{
  return(item.id!==id)
 
})
setData(newStudentData);
toast.update(deleteid, { render: "Successfull", type: "success", isLoading: false, autoClose: 1000,theme: "light"}); 
})
.catch(err=>console.log(err))
}

const getexportData=async function(id) {
  alert(id);

   const tid =  toast.loading("Fetching data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  await axios.get('/api/salaryslipinfo/'+id)
    .then(res=> {
      console.log(res);
      setSalaryslipinfo(res.data.salaryslipinfo);
      exportData(res.data.salaryslipinfo);
      toast.update(tid, { render: "Successfull", type: "success", isLoading: false, autoClose: 1000,theme: "light"}); //setData(res.data.results);
  })
    .catch(err=>console.log(err));
    

}

const formatDate = (dateString) => {
  // Parse the date string in the format "DD-MM-YYYY"
  const [day, month, year] = dateString.split("-");
  
  // Create a Date object
  const date = new Date(`${year}-${month}-${day}`);
  
  // Format the date to get the full month name and year
  const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(date);
  
  return formattedDate.toUpperCase(); // e.g., "September 2024"
};

 const exportData = async (info) => {
    //alert("Ready for export");
      
    


    // Load existing Excel template
    const workbook = new ExcelJS.Workbook();
    
    try {


        // Fetch the Excel template file from the public folder
        const response = await fetch(`${process.env.PUBLIC_URL}/slip.xlsx`);

        if (!response.ok) {
            throw new Error("Failed to fetch the Excel template.");
        }

        const blob = await response.blob();
        
        // Load the workbook from the blob
        await workbook.xlsx.load(blob);
        
        const worksheet = workbook.getWorksheet(1); // Get the first worksheet

        // Example data to be added to the template
        // const data = [
        //     { sal_staff_name: "Lecturer", sal_staff_designation:"ASSISTANT PROFESSOR", sal_epfhs: "NEPF", sal_fixedsalary: 70000,sal_totaldays:30,sal_presentdays:"2.5",sal_advance:200  },
        //     { sal_staff_name: "Ssss", sal_staff_designation:"sfdksdfjskdf", sal_epfhs: "EPF", sal_fixedsalary: 11000,sal_totaldays:30, sal_presentdays:"1.5", sal_advance:10 },
        // ];
        const data=info;
 console.log("finaldata",data);
// return false;

const dataArray = Object.values(data);
const month=formatDate(dataArray[0]['sal_month']);
worksheet.getCell('A3').value = "SALARY  STATEMENT  FOR THE MONTH OF "+month;
        // Add data to the worksheet (adjust row/column as needed)
        dataArray.forEach((item, index) => {
         
          const targetRow = index + 6; 
          worksheet.insertRow(targetRow);
           // worksheet.getCell(`A${index + 2}`).value = item.name;   // Assuming name is in column A
            worksheet.getCell(`B${targetRow}`).value = item.sal_staff_name;    // Age in column B
            worksheet.getCell(`B${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
            worksheet.getCell(`C${targetRow}`).value = item.sal_staff_designation;  // Salary in column C
             worksheet.getCell(`C${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
            worksheet.getCell(`D${targetRow}`).value = item.sal_epfhs;
             worksheet.getCell(`D${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
            worksheet.getCell(`E${targetRow}`).value = Number(item.sal_fixedsalary);
           
             worksheet.getCell(`E${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
            worksheet.getCell(`F${targetRow}`).value = Number(item.sal_totaldays);
             worksheet.getCell(`F${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
            worksheet.getCell(`G${targetRow}`).value = Number(item.sal_presentdays);
             worksheet.getCell(`G${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
             worksheet.getCell(`R${targetRow}`).value = item.sal_advance;
              worksheet.getCell(`R${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };

            // worksheet.getCell(`H${index + 6}`).value = item.lop;
            // worksheet.getCell(`I${index + 6}`).value = item.sal_lessoflop;
            // worksheet.getCell(`J${index + 6}`).value = item.sal_basicsalary;
            // worksheet.getCell(`K${index + 6}`).value = item.sal_specialsalary;
          
          worksheet.getCell(`H${targetRow}`).value = 
          {
              formula: `E${targetRow}/F${targetRow}*(F${targetRow}-G${targetRow})`,
              result: null
          };
           worksheet.getCell(`H${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
          worksheet.getCell(`I${targetRow}`).value = 
          {
              formula: `E${targetRow}-H${targetRow}`,
              result: null
          };
           worksheet.getCell(`I${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
          worksheet.getCell(`J${targetRow}`).value = 
          {
              formula: `I${targetRow}*0.5`,
              result: null
          };
           worksheet.getCell(`J${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
          worksheet.getCell(`K${targetRow}`).value = 
          {
              formula: `I${targetRow}*0.1`,
              result: null
          };
           worksheet.getCell(`K${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
          worksheet.getCell(`L${targetRow}`).value = 
          {
              formula: `I${targetRow}*0.4`,
              result: null
          };
           worksheet.getCell(`L${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
          worksheet.getCell(`M${targetRow}`).value = 
          {
              formula: `J${targetRow}+K${targetRow}+L${targetRow}`,
              result: null
          };
           worksheet.getCell(`M${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
          worksheet.getCell(`N${targetRow}`).value = 
          {
              formula: `J${targetRow}+K${targetRow}`,
              result: null
          };
           worksheet.getCell(`N${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
          worksheet.getCell(`O${targetRow}`).value = 
          {
              formula: `N${targetRow}*0.12`,
              result: null
          };
           worksheet.getCell(`O${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
          worksheet.getCell(`P${targetRow}`).value = 
          {
              formula: `M${targetRow}*0.0075`,
              result: null
          };
           worksheet.getCell(`P${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
          worksheet.getCell(`Q${targetRow}`).value = 
          {
              formula: `M${targetRow}-O${targetRow}-P${targetRow}`,
              result: null
          };
           worksheet.getCell(`Q${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
          worksheet.getCell(`S${targetRow}`).value = 
          {
              formula: `Q${targetRow}-R${targetRow}`,
              result: null
          };
           worksheet.getCell(`S${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
         worksheet.getCell(`T${targetRow}`).value = 
          {
              formula: `Q${targetRow}-R${targetRow}`,
              result: null
          };
           worksheet.getCell(`T${targetRow}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };

         
            
         
        });

        worksheet.mergeCells(`A${dataArray.length+6}:C${dataArray.length+6}`); // Reapply merge
         worksheet.getCell(`A${dataArray.length+6}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
         worksheet.getCell(`C${dataArray.length+6}`).value = "TOTAL";
         worksheet.getCell(`C${dataArray.length+6}`).font = { bold: true };
         worksheet.getCell(`E${dataArray.length+6}`).value = 
          {
              formula: `SUM(E6:E${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`E${dataArray.length+6}`).font = { bold: true };

          worksheet.getCell(`G${dataArray.length+6}`).value = 
          {
              formula: `SUM(G6:G${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`G${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`H${dataArray.length+6}`).value = 
          {
              formula: `SUM(H6:H${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`H${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`I${dataArray.length+6}`).value = 
          {
              formula: `SUM(I6:I${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`I${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`J${dataArray.length+6}`).value = 
          {
              formula: `SUM(J6:J${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`J${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`K${dataArray.length+6}`).value = 
          {
              formula: `SUM(K6:K${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`K${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`L${dataArray.length+6}`).value = 
          {
              formula: `SUM(L6:L${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`L${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`M${dataArray.length+6}`).value = 
          {
              formula: `SUM(M6:M${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`M${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`N${dataArray.length+6}`).value = 
          {
              formula: `SUM(N6:N${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`N${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`O${dataArray.length+6}`).value = 
          {
              formula: `SUM(O6:O${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`O${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`P${dataArray.length+6}`).value = 
          {
              formula: `SUM(P6:P${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`P${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`Q${dataArray.length+6}`).value = 
          {
              formula: `SUM(Q6:Q${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`Q${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`R${dataArray.length+6}`).value = 
          {
              formula: `SUM(R6:R${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`R${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`S${dataArray.length+6}`).value = 
          {
              formula: `SUM(S6:S${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`S${dataArray.length+6}`).font = { bold: true };
           worksheet.getCell(`T${dataArray.length+6}`).value = 
          {
              formula: `SUM(T6:T${dataArray.length+5})`,
              result: null
          };
          worksheet.getCell(`T${dataArray.length+6}`).font = { bold: true };

          //next row
           worksheet.mergeCells(`A${dataArray.length+7}:C${dataArray.length+7}`); // Reapply merge
         worksheet.getCell(`A${dataArray.length+7}`).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
         worksheet.getCell(`C${dataArray.length+7}`).value = "Net Salary to be paid for the Month";
         worksheet.getCell(`C${dataArray.length+7}`).font = { bold: true };
         worksheet.getCell(`E${dataArray.length+7}`).value = 
          {
              formula: `SUM(E${dataArray.length+6})`,
              result: null
          };

          worksheet.getCell(`G${dataArray.length+7}`).font = { bold: true };
            worksheet.getCell(`O${dataArray.length+7}`).value = 
          {
              formula: `SUM(O${dataArray.length+6})`,
              result: null
          };

           worksheet.getCell(`H${dataArray.length+7}`).value = 
          {
              formula: `SUM(H${dataArray.length+6})`,
              result: null
          };

          worksheet.getCell(`O${dataArray.length+7}`).font = { bold: true };
           worksheet.getCell(`P${dataArray.length+7}`).value = 
          {
              formula: `SUM(P${dataArray.length+6})`,
              result: null
          };
          worksheet.getCell(`P${dataArray.length+7}`).font = { bold: true };
           worksheet.getCell(`Q${dataArray.length+7}`).value = 
          {
              formula: `SUM(Q${dataArray.length+6})`,
              result: null
          };
          worksheet.getCell(`Q${dataArray.length+7}`).font = { bold: true };
           worksheet.getCell(`R${dataArray.length+7}`).value = 
          {
              formula: `SUM(R${dataArray.length+6})`,
              result: null
          };
          worksheet.getCell(`R${dataArray.length+7}`).font = { bold: true };
           worksheet.getCell(`S${dataArray.length+7}`).value = 
          {
              formula: `SUM(S${dataArray.length+6})`,
              result: null
          };
          worksheet.getCell(`S${dataArray.length+7}`).font = { bold: true };
           worksheet.getCell(`T${dataArray.length+7}`).value = 
          {
              formula: `SUM(T${dataArray.length+6})`,
              result: null
          };
          worksheet.getCell(`T${dataArray.length+6}`).font = { bold: true };

       
        console.log(dataArray.length+6);
        //=SUM(E6:E40)

        // Save the updated workbook
        const buffer = await workbook.xlsx.writeBuffer();
        const fileBlob = new Blob([buffer], { type: 'application/octet-stream' });

        // Trigger the download
        saveAs(fileBlob, 'exported_salary_slip.xlsx');

        alert("Export successful!");
    } catch (error) {
        console.error("Error during export:", error);
        alert(`Export failed: ${error.message}`);
    }
};
  
  return (
    <>
      <ToastContainer />
    <div style={{height:'780px'}}>
        <Row style={{ marginRight: '0', marginLeft: '0', padding: '0' }}>
           <Col sm={12} style={{ padding: '0', textAlign:'end',marginBottom:'10px' }}>
              <Link to={'addsalaryslip'}><button type="button" class="btn btn-primary"><FaPlus size={16}/>&nbsp;Generate Salary Slip</button></Link>&nbsp;&nbsp;
               {/* <Link to={'viewbilling'}><button type="button" class="btn btn-warning"><FaSearch size={16}/>&nbsp;View Invoice History</button></Link> */}
            </Col>
          </Row>
        <Row style={{ marginRight: '0', marginLeft: '0', padding: '0' }}>
          <Col sm={12} style={{ padding: '0' }}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={9} className="d-flex align-items-center">
                    {/* <Col sm={3} className="mb-3 text-start">
                      <Button variant="primary"> <BsPlus size={26} />Add Course</Button>
                    </Col> */}
                    {/* <Col sm={1} className="mb-3"></Col> */}
                    <Col sm={2} className="mb-3 d-flex align-items-center">
                      <label className="mr-2">Rows:</label>
                      <Form.Control
                        as="select"
                        value={itemsPerPage}
                        onChange={(e) => {
                          setItemsPerPage(parseInt(e.target.value));
                          setCurrentPage(1);
                        }}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                      </Form.Control>
                    </Col>
                  </Col>
                  <Col sm={3} className="text-end">
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Col>
                </Row>
                <div className="table-responsive border-bottom my-0 mt-0">
                  <Table responsive  id="datatable" className="text-start" size="md" data-toggle="data-table">
                    <thead className='text-start'>
                      <tr>
                        <th style={{}}>Month</th>
                       
                        <th  style={{}}>Created On</th>
                        <th  style={{}}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.length === 0 ? (
                        <tr className='text-center'>
                          <td colSpan="3"  style={{color:'grey'}}>No Data available</td>
                        </tr>
                      ) : (
                        currentItems.map((item, index) => (
                          <tr key={index}>
                            <td style={{ padding: '8px' }}>{item.master_month}</td>
                           
                            <td style={{ padding: '8px' }}>{item.created_at}</td>
                            <td style={{ padding: '8px' }}><button type="button" class="btn btn-sm" onClick={()=>getexportData(item.id)}><FaPrint size={19} style={{color:'blue'}}/></button><Link to={`editsalaryslip/${item.id}`}><button type="button" class="btn btn-sm"><FaEdit size={19} style={{color:'blue'}}/></button></Link>&nbsp;&nbsp;<button type="button" class="btn btn-sm" onClick={()=>showconfirm(item.id)}><FaTrashAlt size={19} style={{color:'red'}}/></button></td>
                            
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between align-items-center">
                    Showing {firstEntryIndex} to {lastEntryIndex} of {totalEntries}
                    <ul className="pagination">
                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => paginate(currentPage - 1)}>
                          Previous
                        </button>
                      </li>
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => paginate(index + 1)}>
                            {index + 1}
                          </button>
                        </li>
                      ))}
                      <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => paginate(currentPage + 1)}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal show={showConfirm}  onHide={handleClose} backdrop="static" keyboard={false} centered={true}>
            <Modal.Header closeButton>
            <Modal.Title as="h5">Delete Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            Are you sure?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="danger" onClick={(e)=>handleDelete(deleteId)}>Delete</Button>
            </Modal.Footer>
        </Modal>
        
    </div>
       
      
    </>
  );
};


export default StaffSalaryslipLandingMain
