import React from 'react'
import App from '../App'
import MainLayout from '../views/layouts/MainLayout'
import Dashboard from '../views/dashboard/Dashboard'

import LandingLayout from '../views/Courses/LandingLayout'
import LandingMain from '../views/Courses/LandingMain'
import CourseAdd from '../views/Courses/CourseAdd'
import CourseEdit from '../views/Courses/CourseEdit'

import StudentLandingLayout from '../views/Students/StudentLandingLayout'
import StudentLandingMain from '../views/Students/StudentLandingMain'
import StudentAdd from '../views/Students/StudentAdd'
import StudentEdit from '../views/Students/StudentEdit'

import StaffLandingLayout from '../views/Staffs/StaffLandingLayout'
import StaffLandingMain from '../views/Staffs/StaffLandingMain'
import StaffAdd from '../views/Staffs/StaffAdd'
import StaffEdit from '../views/Staffs/StaffEdit'
import StaffEditSalary from '../views/Staffs/StaffEditSalary'

import BillingLandingLayout from '../views/Billing/BillingLandingLayout'
import BillingLandingMain from '../views/Billing/BillingLandingMain'
import BillingAdd from '../views/Billing/BillingAdd'
import BillingEdit from '../views/Billing/BillingEdit'
import BillingView from '../views/Billing/BillingView'
import BillingPrint from '../views/Billing/BillingPrint'

import FeesLandingLayout from '../views/Fees/FeesLandingLayout'
import FeesLandingMain from '../views/Fees/FeesLandingMain'
import FeesAdd from '../views/Fees/FeesAdd'
import FeesEdit from '../views/Fees/FeesEdit'

import RolesLandingLayout from '../views/Roles/LandingLayout'
import RolesLandingMain from '../views/Roles/LandingMain'
import RoleAdd from '../views/Roles/RoleAdd'
import RoleEdit from '../views/Roles/RoleEdit'

import AttendanceLandingLayout from '../views/Attendance/AttendanceLandingLayout'
import AttendanceLandingMain from '../views/Attendance/AttendanceLandingMain'
import AttendanceAdd from '../views/Attendance/AttendanceAdd'
import AttendanceEdit from '../views/Attendance/AttendanceEdit'
import AttendanceReport from '../views/Attendance/AttendanceReport'

import TranscertLandingLayout from '../views/TransferCert/TranscertLandingLayout'
import TranscertLandingMain from '../views/TransferCert/TranscertLandingMain'
import TranscertAdd from '../views/TransferCert/TranscertAdd'
import TranscertEdit from '../views/TransferCert/TranscertEdit'
import TranscertPrint from '../views/TransferCert/TranscertPrint'

import SubjectsLandingLayout from '../views/Subjects/SubjectsLandingLayout'
import SubjectsLandingMain from '../views/Subjects/SubjectsLandingMain'
import SubjectsAdd from '../views/Subjects/SubjectsAdd'
import SubjectsEdit from '../views/Subjects/SubjectsEdit'

import StudPerformanceLandingLayout from '../views/StudentPerformance/StudPerformanceLandingLayout'
import StudPerformanceLandingMain from '../views/StudentPerformance/StudPerformanceLandingMain'
import StudPerformanceAdd from '../views/StudentPerformance/StudPerformanceAdd'
import StudPerformanceEdit from '../views/StudentPerformance/StudPerformanceEdit'

import BusFeesLandingLayout from '../views/BusFees/BusFeesLandingLayout'
import BusFeesLandingMain  from '../views/BusFees/BusFeesLandingMain'
import BusFeesAdd from '../views/BusFees/BusFeesAdd'
import BusFeesEdit from '../views/BusFees/BusFeesEdit'

import BusstopLandingLayout from '../views/Busstops/BusstopLandingLayout'
import BusstopLandingMain from '../views/Busstops/BusstopLandingMain'
import BusstopAdd from '../views/Busstops/BusstopAdd'
import BusstopEdit from '../views/Busstops/BusstopEdit'

import HostelFeesLandingLayout from '../views/HostelFees/HostelFeesLandingLayout'
import HostelFeesLandingMain from '../views/HostelFees/HostelFeesLandingMain'
import HostelFeesAdd from '../views/HostelFees/HostelFeesAdd'
import HostelFeesEdit from '../views/HostelFees/HostelFeesEdit'

import BillingBusLandingLayout from '../views/Billingbus/BillingBusLandingLayout'
import BillingBusLandingMain from '../views/Billingbus/BillingBusLandingMain'
import BillingBusAdd from '../views/Billingbus/BillingBusAdd'
import BillingBusView from '../views/Billingbus/BillingBusView'
import BillingBusEdit from '../views/Billingbus/BillingBusEdit'
import BillingBusPrint from '../views/Billingbus/BillingBusPrint'

import BillingHostelLandingLayout from '../views/Billinghostel/BillingHostelLandingLayout'
import BillingHostelLandingMain from '../views/Billinghostel/BillingHostelLandingMain'
import BillingHostelAdd from '../views/Billinghostel/BillingHostelAdd'
import BillingHostelView from '../views/Billinghostel/BillingHostelView'
import BillingHostelEdit from '../views/Billinghostel/BillingHostelEdit'
import BillingHostelPrint from '../views/Billinghostel/BillingHostelPrint'

import StaffAttendanceLandingLayout from '../views/StaffAttendance/StaffAttendanceLandingLayout'
import StaffAttendanceLandingMain from '../views/StaffAttendance/StaffAttendanceLandingMain'
import AttendanceAddStaff from '../views/StaffAttendance/AttendanceAddStaff'
import AttendanceEditStaff from '../views/StaffAttendance/AttendanceEditStaff'

import StaffSalaryslipLandingLayout from '../views/StaffSalaryslip/StaffSalaryslipLandingLayout'
import StaffSalaryslipLandingMain from '../views/StaffSalaryslip/StaffSalaryslipLandingMain'
import AddStaffSalarySlip from '../views/StaffSalaryslip/AddStaffSalarySlip'
import EditStaffSalarySlip from '../views/StaffSalaryslip/EditStaffSalarySlip'

import AttendanceReportLandingLayout from '../views/Reports/attendance/AttendanceReportLandingLayout'
import AttendanceReportLandingMain from '../views/Reports/attendance/AttendanceReportLandingMain'
import BillingReportLandingMain from '../views/Reports/attendance/BillingReportLandingMain'
import PerformanceReportLandingMain from '../views/Reports/attendance/PerformanceReportLandingMain'

export const CustomRouter = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: 'dashboard',
                element: <Dashboard valueFromRoute="DASHBOARD" valueFromSubroute=""/>
            },
            {
                path: "coursemanagement",
                exact: true,
                element:<LandingLayout/>,
                children: [
                {
                    path: "", // Empty path for the "hipaa" route itself
                    exact: true,
                    element: <LandingMain/>, // Replace with the appropriate component or content
                },
                {
                    path: "addcourse", // Empty path for the "hipaa" route itself
                    exact: true,
                    element: <CourseAdd/>, // Replace with the appropriate component or content
                }
                ,
                {
                    path: "editcourse/:id", // Empty path for the "hipaa" route itself
                    exact: true,
                    element: <CourseEdit/>, // Replace with the appropriate component or content
                }
                
               
                ]
            },
            {
                path: "studentmanagement",
                exact: true,
                element:<StudentLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudentLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addstudent", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudentAdd/>, // Replace with the appropriate component or content
                    }
                    ,
                    {
                        path: "editstudent/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudentEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "staffmanagement",
                exact: true,
                element:<StaffLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StaffLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addstaff", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StaffAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editstaff/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StaffEdit/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editstaffsalary/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StaffEditSalary/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "staffsalaryslip",
                exact: true,
                element:<StaffSalaryslipLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StaffSalaryslipLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addsalaryslip", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AddStaffSalarySlip/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editsalaryslip/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <EditStaffSalarySlip/>, // Replace with the appropriate component or content
                    },
                ],
            },
            {
                path: "billing",
                exact: true,
                element:<BillingLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addbilling", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editbilling/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingEdit/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "viewbilling", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingView/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "printbilling/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingPrint/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "feesmanagement",
                exact: true,
                element:<FeesLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <FeesLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addfee", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <FeesAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editfee/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <FeesEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "busfeesmanagement",
                exact: true,
                element:<BusFeesLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BusFeesLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "busaddfee", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BusFeesAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "buseditfee/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BusFeesEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "busstopmanagement",
                exact: true,
                element:<BusstopLandingLayout/>,
                children: [
                {
                    path: "", // Empty path for the "hipaa" route itself
                    exact: true,
                    element: <BusstopLandingMain/>, // Replace with the appropriate component or content
                },
                {
                    path: "addbusstop", // Empty path for the "hipaa" route itself
                    exact: true,
                    element: <BusstopAdd/>, // Replace with the appropriate component or content
                }
                ,
                {
                    path: "editbusstop/:id", // Empty path for the "hipaa" route itself
                    exact: true,
                    element: <BusstopEdit/>, // Replace with the appropriate component or content
                }
                
               
                ]
            },
            {
                path: "rolemanagement",
                exact: true,
                element:<RolesLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <RolesLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addrole", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <RoleAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editrole/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <RoleEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "studentattendance",
                exact: true,
                element:<AttendanceLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addattendance", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editattendance/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceEdit/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "attendancereport", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceReport/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "transfercertificate",
                exact: true,
                element:<TranscertLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <TranscertLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addtranscert", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <TranscertAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "edittranscert/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <TranscertEdit/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "printtranscert/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <TranscertPrint/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "subjectmanagment",
                exact: true,
                element:<SubjectsLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <SubjectsLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addsubject", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <SubjectsAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editsubject/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <SubjectsEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "studentperformance",
                exact: true,
                element:<StudPerformanceLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudPerformanceLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addperformance", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudPerformanceAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editperformance/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudPerformanceEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "hostelfeesmanagment",
                exact: true,
                element:<HostelFeesLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <HostelFeesLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "hosteladdfee", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <HostelFeesAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "hosteleditfee/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <HostelFeesEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "billingbus",
                exact: true,
                element:<BillingBusLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingBusLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addbillingbus", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingBusAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editbillingbus/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingBusEdit/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "viewbillingbus", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingBusView/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "printbillingbus/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingBusPrint/>, // Replace with the appropriate component or content
                    }
                ]
            }
            ,
            {
                path: "billinghostel",
                exact: true,
                element:<BillingHostelLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingHostelLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addbillinghostel", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingHostelAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editbillinghostel/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingHostelEdit/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "viewbillinghostel", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingHostelView/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "printbillinghostel/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingHostelPrint/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "staffattendance",
                exact: true,
                element:<StaffAttendanceLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StaffAttendanceLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addattendancestaff", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceAddStaff/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editattendancestaff/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceEditStaff/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "attendancereport", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceReport/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "attendancereport",
                exact: true,
                element:<AttendanceReportLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceReportLandingMain/>, // Replace with the appropriate component or content
                    }
                    // ,
                    // {
                    //     path: "billingreport", // Empty path for the "hipaa" route itself
                    //     exact: true,
                    //     element: <BillingReportLandingMain/>, // Replace with the appropriate component or content
                    // },
                ]
            },
            {
                path: "billingreport",
                exact: true,
                element:<AttendanceReportLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingReportLandingMain/>, // Replace with the appropriate component or content
                    }
                   
                ]
            },
            {
                path: "performancereport",
                exact: true,
                element:<AttendanceReportLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <PerformanceReportLandingMain/>, // Replace with the appropriate component or content
                    }
                   
                ]
            }
        ]
       
    }
]

 // ,
                    // {
                    //     path: "billingreport", // Empty path for the "hipaa" route itself
                    //     exact: true,
                    //     element: <BillingReportLandingMain/>, // Replace with the appropriate component or content
                    // },