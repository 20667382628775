import React,{useState,useRef, useEffect} from 'react'
import { Form, Nav , Tab, Row, Col, InputGroup,Button ,Image} from 'react-bootstrap'

import Card from '../../components/defaultSnippets/Card';
import { Link } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';
import avatars1 from '../../assets/images/avatars/01.png'
import avatars2 from '../../assets/images/avatars/avtar_1.png'
import avatars3 from '../../assets/images/avatars/avtar_2.png'
import avatars4 from '../../assets/images/avatars/avtar_3.png'
import avatars5 from '../../assets/images/avatars/avtar_4.png'
import avatars6 from '../../assets/images/avatars/avtar_5.png'
import { useSelector } from 'react-redux';
import { selectStudentStatus } from '../Students/studentSlice';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
const StudentAdd = () => {
 const [formData, setFormData] = useState({});
 const fileInputRef = useRef(null);
 const studentData=useSelector(selectStudentStatus);
 const [citiesForState, setCitiesForState] = useState([]);
 const[imagePreview,setImagePreview]=useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const[duration,setDuration] = useState('');
  const[busstops, setBusstops] = useState('');
  const[isdataloaded,setIsDataLoaded]=useState(false);
  const navigate = useNavigate();
   
   useEffect(() => {
    const fetchData = async () => {
      try {
       
        const response = await axios.get('/api/busstoplist');
        console.log(response);
        setBusstops(response.data.results);
        // Set your data or handle the response here
        setIsDataLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error here
      }
    };

    fetchData();
  }, []);


   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleyearChange = (e) => {
    setSelectedYear(e.target.value);
     setFormData(prevFormData => ({
            ...prevFormData,
            academic_year: e.target.value // Pre-select city based on fetched data
        }));
  };

  const handleStateChange = (event) => {
    const selectedStateId = parseInt(event.target.value); // Convert to number
    setFormData({ ...formData, state: selectedStateId }); // Update formData.state

    // Filter cities based on the selected state ID
    //alert(selectedStateId);
    console.log(studentData.citynames.data.results);
    const cities = studentData.citynames.data.results.filter(
      (city) => city.state_id === selectedStateId
    );
    setCitiesForState(cities);
    console.log(cities);
   // setFormData({ ...formData, city: '' });
  };

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setFormData({ ...formData, city: selectedCityId }); // Update formData.city
  };

   const handleCourseChange = (event) => {
    const selectedCourseId = parseInt(event.target.value);
    setFormData({ ...formData, current_program: selectedCourseId }); // Update formData.city
   // Find the course object with the selectedCourseId
   console.log(selectedCourseId);
   console.log(studentData.coursenames.data.results);
  const selectedCourse = studentData.coursenames.data.results.find(course => course.id === selectedCourseId);
  console.log(selectedCourse);
  if (selectedCourse) {
    // Extract the course_duration from the selected course
    const courseDuration = selectedCourse.course_duration;
    // Now you can do whatever you want with the courseDuration, such as setting it in the state
    console.log(courseDuration);
    setDuration(courseDuration);
  }
  };



  // Function to generate options for past four years
const generateYearOptions = () => {
   let temp;
   if(duration===''){
       temp=4;
   }
   else{
       temp=duration;
   }
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= currentYear - 2020; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + parseInt(temp);
    years.push(`${startYear}-${endYear}`);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};

  const handleDateChange = (selectedDates, dateField) => {
    // const selectedDate = selectedDates[0];
    // setFormData({
    //   ...formData,
    //   [dateField]: selectedDate,
    // });
    const selectedDate = selectedDates[0];
    let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
    formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
    setFormData({
        ...formData,
        [dateField]: formattedDate, // Set the formatted date in the formData
    });
  };

  

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setFormData({
//       ...formData,
//       profilePic: file, // Set profilePic in formData to the selected file
//     });
//     console.log(formData);
//   };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
        ...formData,
        profilePic: file, // Set profilePic in formData to the selected file
    });

    // Show image preview
    const reader = new FileReader();
    reader.onload = (event) => {
        // Set the preview image source
        setImagePreview(event.target.result);
    };
    reader.readAsDataURL(file); // Read the selected file as a data URL
};



const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new FormData instance
    const formDataToSend = new FormData();

    // Append all form data fields to formDataToSend
    Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
    });

    // Append the profilePic file to formDataToSend
    formDataToSend.append('profilePic', formData.profilePic);

    console.log(formDataToSend);

    // Now you can send formDataToSend to your API
    SendData(formDataToSend);
};

  const SendData = async(formDataToSend) =>{
  
     const studstoreid =  toast.loading("Saving data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.post('/api/studentadd', formDataToSend);

        // If request is successful
        console.log(res.data);
        toast.update(studstoreid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/studentmanagement');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(studstoreid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(studstoreid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }


  return (
    <div>
         <ToastContainer />
         {isdataloaded && (
            <Row>
               <Col xl="3" lg="4" className="">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Add New User</h4>
                        </div>
                     </Card.Header>
                     <Card.Body className="text-start">
                        <Form>
                           <Form.Group className="form-group">
                                <div className="profile-img-edit position-relative">
                                    {imagePreview ? (
                                        <Image src={imagePreview} alt="profile-preview" className="profile-pic rounded avatar-100" />
                                    ) : (
                                        <>
                                            <Image className="theme-color-default-img profile-pic rounded avatar-100" src={avatars1} alt="profile-pic"/>
                                            <Image className="theme-color-purple-img profile-pic rounded avatar-100" src={avatars2} alt="profile-pic"/>
                                            <Image className="theme-color-blue-img profile-pic rounded avatar-100" src={avatars3} alt="profile-pic"/>
                                            <Image className="theme-color-green-img profile-pic rounded avatar-100" src={avatars5} alt="profile-pic"/>
                                            <Image className="theme-color-yellow-img profile-pic rounded avatar-100" src={avatars6} alt="profile-pic"/>
                                            <Image className="theme-color-pink-img profile-pic rounded avatar-100" src={avatars4} alt="profile-pic"/>
                                        </>
                                    )}
                                    <div className="upload-icone bg-primary">
                                        <label htmlFor="file-upload" style={{cursor:'pointer'}}>
                                            <svg className="upload-button" width="14" height="14" viewBox="0 0 24 24">
                                                <path fill="#ffffff" d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />
                                            </svg>
                                        </label>
                                        <Form.Control
                                            id="file-upload"
                                            className="file-upload"
                                            type="file"
                                            accept="image/*"
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>

                              <div className="img-extension mt-3">
                                 <div className="d-inline-block align-items-center">
                                    <span>Only</span>{' '}
                                    <Link to="#">.jpg</Link>{' '}
                                    <Link to="#">.png</Link>{' '}
                                    <Link to="#">.jpeg</Link>{' '}
                                    <span>allowed</span>
                                 </div>
                              </div>
                           </Form.Group>
                           <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="pno">Student ID Number:</Form.Label>
                                    <Form.Control type="text"  id="student_id_number" name="student_id_number" placeholder="" value={formData.student_id_number} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="city">Aadhar Number:</Form.Label>
                                    <Form.Control type="text"  id="government_id" name="government_id" placeholder="" value={formData.government_id} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="lname">Date of Birth:</Form.Label>
                                    {/* <Form.Control type="text"  id="date_of_birth" name="date_of_birth" placeholder="Last Name" value={formData.date_of_birth} onChange={handleChange}/> */}
                                    <Flatpickr
          id="date_of_birth"
          name="date_of_birth"
          value={formData.graduation_date}
          onChange={(selectedDates) =>
            handleDateChange(selectedDates, 'date_of_birth')
          }
          options={{
            dateFormat: 'd-m-Y', // Customize date format
            altInput: true, // Enable alternate input
            altFormat: 'd-m-Y', // Alternate input format
          }}
          className="form-control" // Add any additional CSS classes here
        />
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label>Gender:</Form.Label>
                                    <select name="gender" id="gender" className="selectpicker form-control" data-style="py-0" value={formData.gender} onChange={handleChange}>
                                       <option>Select</option>
                                       <option>Male</option>
                                       <option>Female</option>
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="previous_education">Previous Education:</Form.Label>
                                    <Form.Control type="text"  id="previous_education" name="previous_education" placeholder="" value={formData.previous_education} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="current_program">Current Course:</Form.Label>
                                    {/* <Form.Control type="text"  id="current_program" name="current_program" placeholder="" value={formData.current_program} onChange={handleChange}/> */}
                                     <select
                                        name="current_program"
                                        id="current_program"
                                        className="selectpicker form-control"
                                        onChange={handleCourseChange}
                                    >
                                        <option>Select Course</option>
                                        {studentData.coursenames.data.results.map((course, index) => (
                                        <option key={index} value={course.id}>
                                            {course.coursename}
                                        </option>
                                        ))}
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
      <Form.Label>Academic Year:</Form.Label>
      <select
        name="academic_year"
        id="academic_year"
        className="selectpicker form-control"
        data-style="py-0"
        value={selectedYear}
        onChange={handleyearChange}
      >
        <option value="">Select</option>
        {generateYearOptions()}
      </select>
    </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="admission_date">Admission Date:</Form.Label>
                                    {/* <Form.Control type="text"  id="admission_date" name="admission_date" placeholder="" value={formData.admission_date} onChange={handleChange}/> */}
                                   <Flatpickr
                                       id="admission_date"
                                       name="admission_date"
                                       value={formData.admission_date}
                                       onChange={(selectedDates) =>
                                          handleDateChange(selectedDates, 'admission_date')
                                       }
                                       options={{
                                          dateFormat: 'd-m-Y', // Customize date format
                                          altInput: true, // Enable alternate input
                                          altFormat: 'd-m-Y', // Alternate input format
                                          maxDate: new Date(),  // Show previous 1 week + future dates
                                       }}
                                       className="form-control" // Add any additional CSS classes here
                                    />
                                 </Form.Group>

                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="busstop">Bus Stop:</Form.Label>
                                    {/* <Form.Control type="text"  id="current_program" name="current_program" placeholder="" value={formData.current_program} onChange={handleChange}/> */}
                                     <select
                                        name="busstop"
                                        id="busstop"
                                        className="selectpicker form-control"
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Stop</option>
                                        {busstops.map((busstop, index) => (
                                        <option key={index} value={busstop.id}>
                                            {busstop.busstopname}
                                        </option>
                                        ))}
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label>Hostel:</Form.Label>
                                    <select name="hostel" id="hostel" className="selectpicker form-control" data-style="py-0"  value={formData.hostel} onChange={handleChange}>
                                       <option value="1">Yes</option>
                                       <option value="0">No</option>
                                    </select>
                                 </Form.Group>
                                                           
                        </Form>
                     </Card.Body>
                  </Card>
               </Col>
               <Col xl="9" lg="8">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">New User Information</h4>
                        </div>
                     </Card.Header>
                     <Card.Body  className="text-start">
                        <div className="new-user-info">
                            <Form className="form-horizontal" onSubmit={(e)=>handleSubmit(e)}>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="first_name">First Name:</Form.Label>
                                    <Form.Control type="text"  id="first_name" name="first_name" placeholder="First Name" value={formData.first_name} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="last_name">Last Name:</Form.Label>
                                    <Form.Control type="text"  id="last_name" name="last_name" placeholder="Last Name" value={formData.last_name} onChange={handleChange}/>
                                 </Form.Group>
                                 
                                 
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="address">Address:</Form.Label>
                                    <Form.Control type="text"  id="address" name="address" placeholder=""  value={formData.address} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-4 form-group">
                                    <Form.Label>State:</Form.Label>
                                    <select
                                        name="state"
                                        id="state"
                                        className="selectpicker form-control"
                                        value={formData.state}
                                        onChange={handleStateChange}
                                    >
                                        <option>Select State</option>
                                        {studentData.statenames.data.results.map((state, index) => (
                                        <option key={index} value={state.id}>
                                            {state.name}
                                        </option>
                                        ))}
                                    </select>
                                    </Form.Group>

                                    <Form.Group className="col-md-4 form-group">
                                    <Form.Label>City:</Form.Label>
                                    <select
                                        name="city"
                                        id="city"
                                        className="selectpicker form-control"
                                        value={formData.city}
                                        onChange={handleCityChange}
                                    >
                                        <option value=''>Select City</option>
                                        {citiesForState.map((city, index) => (
                                        <option key={index} value={city.id}>
                                            {city.city}
                                        </option>
                                        ))}
                                    </select>
                                    </Form.Group>

                                 <Form.Group className="col-md-4 form-group">
                                    <Form.Label htmlFor="add1">Postal Code:</Form.Label>
                                    <Form.Control type="text"  id="postal_code" name="postal_code" placeholder=""  value={formData.postal_code} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="add1">Email:</Form.Label>
                                    <Form.Control type="email"  id="email" name="email" placeholder=""  value={formData.email} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6  form-group">
                                    <Form.Label htmlFor="mobno">Mobile Number:</Form.Label>
                                    <Form.Control type="text"  id="phone_number" name="phone_number" placeholder=""  value={formData.phone_number} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6  form-group">
                                    <Form.Label htmlFor="altconno">Emergency Contact Person:</Form.Label>
                                    <Form.Control type="text"  id="emergency_contact_name" name="emergency_contact_name" placeholder=""  value={formData.emergency_contact_name} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6  form-group">
                                    <Form.Label htmlFor="altconno">Emergency Contact Number:</Form.Label>
                                    <Form.Control type="text"  id="emergency_contact_phone" name="emergency_contact_phone" placeholder=""  value={formData.emergency_contact_phone} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6  form-group">
                                    <Form.Label htmlFor="email">Nationality:</Form.Label>
                                    <Form.Control type="text"  id="nationality" name="nationality" placeholder=""  value={formData.nationality} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-3 form-group">
                                    <Form.Label>Caste:</Form.Label>
                                    <select name="caste" id="caste" className="selectpicker form-control" data-style="py-0"  value={formData.caste} onChange={handleChange}>
                                       <option value="">Select Caste</option>
                                       <option value="OC">OC</option>
                                       <option value="BC">BC</option>
                                       <option value="BCM">BCM</option>
                                       <option value="MBC">MBC</option>
                                       <option value="SC">SC</option>
                                       <option value="SCA">SCA</option>
                                       <option value="ST">ST</option>
                                       <option value="SCC">SCC</option>
                                    </select>
                                 </Form.Group>
                                  <Form.Group className="col-md-3 form-group">
                                    <Form.Label>Category(Quota):</Form.Label>
                                    <select name="quota" id="quota" className="selectpicker form-control" data-style="py-0"  value={formData.quota} onChange={handleChange}>
                                       <option value="">Select Caste</option>
                                       <option value="gq_fg">GQ/FG</option>
                                       <option value="gq_nfg">GQ/NFG</option>
                                       <option value="mq_oc">MQ/OC/BC/BCM/MBC</option>
                                       <option value="mq_sc">MQ/SC/SCA/ST/SCC</option>
                                        <option value="quota_75">7.5</option>
                                    </select>
                                 </Form.Group>
                                
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="city">Medical illness(if any):</Form.Label>
                                    <Form.Control type="text"  id="health_conditions" name="health_conditions" placeholder=""  value={formData.health_conditions} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-5 form-group">
                                    <Form.Label htmlFor="city">Parent/Guardian Name:</Form.Label>
                                    <Form.Control type="text"  id="parent_guardian_name" name="parent_guardian_name" placeholder=""  value={formData.parent_guardian_name} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-4 form-group">
                                    <Form.Label htmlFor="city">Parent/Guardian Contact:</Form.Label>
                                    <Form.Control type="text"  id="parent_guardian_phone" name="parent_guardian_phone" placeholder=""  value={formData.parent_guardian_phone} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-3 form-group">
                                    <Form.Label>Relationship:</Form.Label>
                                    <select name="parent_guardian_relationship" id="parent_guardian_relationship" className="selectpicker form-control" data-style="py-0"  value={formData.parent_guardian_relationship} onChange={handleChange}>
                                       <option>Select</option>
                                       <option>Father</option>
                                       <option>Mother</option>
                                       <option >Guardian</option>
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label>Current Year:</Form.Label>
                                    <select name="current_year" id="current_year" className="selectpicker form-control" data-style="py-0"  value={formData.current_year} onChange={handleChange}>
                                       <option>Select</option>
                                       <option>I</option>
                                       <option>II</option>
                                       <option >III</option>
                                       <option >IV</option>
                                    </select>
                                 </Form.Group>
                                  <Form.Group className="col-md-6 form-group">
                                                                <Form.Label htmlFor="city">Graduation Date:</Form.Label>
                                                                {/* <Form.Control type="text"  id="graduation_date" name="graduation_date" placeholder="" value={formData.graduation_date} onChange={handleChange}/> */}
                                                                <Flatpickr
                                    id="graduation_date"
                                    name="graduation_date"
                                    value={formData.graduation_date}
                                    onChange={(selectedDates) =>
                                        handleDateChange(selectedDates, 'graduation_date')
                                    }
                                    options={{
                                        dateFormat: 'd-m-Y', // Customize date format
                                        altInput: true, // Enable alternate input
                                        altFormat: 'd-m-Y', // Alternate input format
                                    }}
                                    className="form-control" // Add any additional CSS classes here
                                    />
                                                            </Form.Group>
                                    
                              </div>
                              {/* <hr/> */}
                              
                              <Button type="submit" variant="btn btn-primary">Add New User</Button>
                            </Form>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
            )}
         </div>
          
  );
}

export default StudentAdd;
